<template>
  <div>
    <v-card outlined width="100%" height="200px" class="my-2">
      <v-card-title>Import Modifiers</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <file-upload
              class="v-btn v-btn__content"
              extensions="json"
              accept="application/json"
              v-model="filesMod"
              input-id="filesMod"
              @input-filter="inputFilterMod"
              @input-file="inputFileMod"
            >
              <i class="fa fa-plus"></i>
              Select file
            </file-upload>
          </v-col>

          <v-col cols="4" v-if="filesMod && filesMod.length > 0">
            Found
            <b>{{ importCount }}</b> modifiers to import
            <v-checkbox
              :disabled="true"
              v-model="merge"
              label="Merge Exisitng Data"
            ></v-checkbox>

            <v-btn color="success" small @click.prevent="uploadMod"
              >Upload</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import FileUpload from 'vue-upload-component'
export default {
  props: {
    uid: {
      type: String,
      default: null
    },
    userProfile: {
      type: Object,
      required: true
    }
  },
  components: {
    FileUpload
  },
  data() {
    return {
      importCount: 0,
      filesMod: [],
      merge: false,
      uploadProgress: false
    }
  },
  computed: {
    filename() {
      return moment().format('MM_DD_YYYY') + '_modifiers_data'
    }
  },
  methods: {
    inputFileMod(newFile, oldFile) {
      if (this.filesMod.length < 1) {
        return
      }
      if (newFile && !oldFile) {
        this.$nextTick(function() {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }

      const fr = new FileReader()
      fr.readAsText(this.filesMod[0].file)

      fr.onload = evt => {
        this.data = JSON.parse(evt.target.result)
        if (this.data && this.data.length > 0) {
          this.importCount = this.data.length

          this.merge = this.data[0].uid === this.uid
        }
      }
    },
    inputFilterMod(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },

    uploadMod() {
      this.uploadProgress = true

      this.$store
        .dispatch('importModifiers', {
          data: this.data,
          uid: this.uid,
          merge: this.merge,
          profile: this.userProfile
        })
        .then(() => {
          this.filesMod = []

          this.$store
            .dispatch('setMessage', {
              color: 'info',
              title: 'Imported',

              body: 'Modifiers are imported.'
            })
            .then(() => {
              if (this.userProfile.status !== 'approved') {
                this.$router.push('/profile/wizard/menus/manage_modifiers')
              }
            })
        })
    }
  }
}
</script>
